<template>
  <div className="animated fadeIn">
    <i class="icon-menu mr-1"></i> Revenue List<hr />
        <v-server-table 
          id="dataTableRevenueList" 
          ref="reports-revenue-list"
          name="dataTableRevenueList" 
          class="bg-light vue-table-table mb-4" 
          :columns="columns" 
          :url="url" 
          :options="options" 
          theme="bootstrap4" 
          :useVuex="true"
          :preserveState="true"
        >
          <template slot="dateSubmitted" slot-scope="props">
            {{ $moment(props.row.dateSubmitted).format('YYYY-MM-DD') }}
          </template>
          <template slot="totalRevenue" slot-scope="props">
            ${{ props.row.totalRevenue.toFixed(2) }}
          </template>
          <template slot="royalties" slot-scope="props">
            ${{ props.row.royalties.toFixed(2) }}
          </template>
          <b-button-group slot="action" slot-scope="props">
            <b-btn size="sm" variant="info" @click="selectedReport = props.row.id">View</b-btn>
            <b-btn v-if="currentUserRole === 'ROLE_ADMIN'" size="sm" variant="danger" @click="reportsRevenueDelete(props.row.id)">Delete</b-btn>
          </b-button-group>
        </v-server-table>
        
        <b-modal
          ref="modal-revenue-details"
          id="modal-details"
          size="lg"
          ok-only
          scrollable
          title="Details"
          @hide="selectedReport = null"
        >
          <RevenueDetails :rid="selectedReport" />
        </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '../../services/http-common'
import { ServerTable } from 'vue-tables-2-premium'
import { mapGetters, mapActions } from 'vuex'
import daterangepicker from 'daterangepicker'
import RevenueDetails from './RevenueDetails'
Vue.use(ServerTable)
require('daterangepicker/daterangepicker.css')
window.$ = require('jquery')
window.JQuery = require('jquery')
window.moment = require('moment')

export default {
  name: 'RevenueList',
  components: {
    RevenueDetails
  },
  data: function () {
    return {
      selectedReport: null,
      options: {
        filterByColumn: true,
        dateColumns: ['dateSubmitted'],
        orderBy: { 
          column: 'dateSubmitted',
          ascending:false 
        },
        perPage: 30,
        perPageValues: [30,60,90],
        headings: {
          id: 'Rept ID', 
          'user.fullName': 'User',
          // 'business.areaName': 'Franchise', 
          dateSubmitted: 'Submitted', 
          reptYr: 'Rept Yr', 
          reptMo: 'Rept Mo', 
          totalRevenue: 'Revenue', 
          royalties: 'Royalties'
        },
        sortable: ['id', /* 'business.areaName',  */'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo', 'totalRevenue', 'royalties'],
        filterable: ['id', /* 'business.areaName',  */'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        },
        requestFunction (data) {
            return HTTP.get(this.url, {
                params: data
            }).catch(function (e) {
                this.dispatch('error', e);
            }.bind(this));
        },
        requestAdapter (data) {
          let param = ''
          let query = {}
          for (let key in data.query) {
            switch (key) {
              case 'dateSubmitted': 
                param = 'dateSubmitted[after]'
                query[param] = data.query.dateSubmitted.start
                param = 'dateSubmitted[before]'
                query[param] = data.query.dateSubmitted.end
                break
              default: 
                query[key] = data.query[key]
            }
          }
          query.page = data.page ? data.page : '1'
          if (data.orderBy) {
            param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[dateSubmitted]'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'participants',
      'business'
    ]),
    columns () {
      let cols = ['id', /* 'business.areaName',  */'user.fullName', 'dateSubmitted', 'reptYr', 'reptMo', 'totalRevenue', 'royalties', 'action']
      // if (this.currentBusiness.id) {
      //   cols.splice(1, 1)
      // }
      return cols
    },
    url () {
      return this.currentBusiness && this.currentBusiness.id ? '/reports_revenues?business=' + this.currentBusiness.id : '/reports_revenues'
    }
  },
  methods: {
    ...mapActions([
      'getReportsRevenue',
      'deleteReportsRevenue',
      'resetReports'
    ]),
    reportsRevenueDelete (id) {
      this.$bvModal.msgBoxConfirm('Please click OK to permanently delete this report (#' + id + ').')
      .then(conf => {
        if (conf) {
          this.deleteReportsRevenue(id).then(() => {
            this.$refs['reports-revenue-list'].refresh()
          })
        }
      })
    }
  },
  destroyed () {
    this.resetReports()
  },
  watch: {
    selectedReport (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['modal-revenue-details'].show()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-size: .8rem;
  font-weight: normal;
}
[class$="-filter-wrapper"] .form-control {
  max-width: 60px;
}
[class="VueTables__user.fullName-filter-wrapper"] .form-control {
  max-width: 150px;
}
</style>
